export const PREDICTO_ABI = [
    {
        type: 'constructor',
        inputs: [
            {
                name: '_tokenAddress',
                type: 'address',
                internalType: 'address',
            },
            { name: '_pAddress', type: 'address', internalType: 'address' },
            {
                name: '_marketCreator',
                type: 'address',
                internalType: 'address',
            },
            {
                name: '_description',
                type: 'string',
                internalType: 'string',
            },
            {
                name: '_marketOutcomes',
                type: 'string[]',
                internalType: 'string[]',
            },
            {
                name: '_tokenDecimals',
                type: 'uint256',
                internalType: 'uint256',
            },
            {
                name: '_endTimestamp',
                type: 'uint256',
                internalType: 'uint256',
            },
            { name: '_minPrice', type: 'uint256', internalType: 'uint256' },
            { name: '_maxPrice', type: 'uint256', internalType: 'uint256' },
            { name: '_fee', type: 'uint256', internalType: 'uint256' },
        ],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'amplifyRewards',
        inputs: [{ name: '_amount', type: 'uint256', internalType: 'uint256' }],
        outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'calculateWinnings',
        inputs: [
            {
                name: 'userAddress',
                type: 'address',
                internalType: 'address',
            },
        ],
        outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'cancelMarket',
        inputs: [],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'getContractBalance',
        inputs: [],
        outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'getParticipants',
        inputs: [{ name: 'outcome', type: 'uint256', internalType: 'uint256' }],
        outputs: [{ name: '', type: 'address[]', internalType: 'address[]' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'market',
        inputs: [],
        outputs: [
            { name: 'admin', type: 'address', internalType: 'address' },
            {
                name: 'tokenAddress',
                type: 'address',
                internalType: 'address',
            },
            { name: 'description', type: 'string', internalType: 'string' },
            {
                name: 'tokenDecimals',
                type: 'uint256',
                internalType: 'uint256',
            },
            { name: 'minPrice', type: 'uint256', internalType: 'uint256' },
            { name: 'maxPrice', type: 'uint256', internalType: 'uint256' },
            { name: 'fee', type: 'uint256', internalType: 'uint256' },
            {
                name: 'totalRewards',
                type: 'uint256',
                internalType: 'uint256',
            },
            { name: 'totalBets', type: 'uint256', internalType: 'uint256' },
            {
                name: 'totalSponsors',
                type: 'uint256',
                internalType: 'uint256',
            },
            {
                name: 'endTimestamp',
                type: 'uint256',
                internalType: 'uint256',
            },
            { name: 'outcomes', type: 'uint256', internalType: 'uint256' },
            {
                name: 'finalOutcome',
                type: 'uint256',
                internalType: 'uint256',
            },
            { name: 'resolved', type: 'bool', internalType: 'bool' },
            { name: 'cancelled', type: 'bool', internalType: 'bool' },
        ],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'outcomes',
        inputs: [{ name: 'index', type: 'uint256', internalType: 'uint256' }],
        outputs: [
            { name: 'name', type: 'string', internalType: 'string' },
            {
                name: 'totalAmount',
                type: 'uint256',
                internalType: 'uint256',
            },
            { name: 'totalBets', type: 'uint256', internalType: 'uint256' },
        ],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'owner',
        inputs: [],
        outputs: [{ name: '', type: 'address', internalType: 'address' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'participate',
        inputs: [
            { name: '_outcome', type: 'uint256', internalType: 'uint256' },
            { name: '_amount', type: 'uint256', internalType: 'uint256' },
        ],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'renounceOwnership',
        inputs: [],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'resolveMarket',
        inputs: [
            { name: '_outcome', type: 'uint256', internalType: 'uint256' },
        ],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'retrieveFunds',
        inputs: [],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'sponsorAmounts',
        inputs: [{ name: 'sponsor', type: 'address', internalType: 'address' }],
        outputs: [{ name: 'amount', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'sponsors',
        inputs: [{ name: 'index', type: 'uint256', internalType: 'uint256' }],
        outputs: [
            { name: 'sponsor', type: 'address', internalType: 'address' },
        ],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'transferOwnership',
        inputs: [
            { name: 'newOwner', type: 'address', internalType: 'address' },
        ],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'updateMarket',
        inputs: [
            {
                name: '_description',
                type: 'string',
                internalType: 'string',
            },
            {
                name: '_endTimestamp',
                type: 'uint256',
                internalType: 'uint256',
            },
            { name: '_minPrice', type: 'uint256', internalType: 'uint256' },
            { name: '_maxPrice', type: 'uint256', internalType: 'uint256' },
        ],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'updatePlatformDetails',
        inputs: [
            { name: '_address', type: 'address', internalType: 'address' },
            { name: '_fee', type: 'uint256', internalType: 'uint256' },
        ],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'userAmounts',
        inputs: [{ name: 'user', type: 'address', internalType: 'address' }],
        outputs: [{ name: 'amount', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'userBets',
        inputs: [{ name: 'user', type: 'address', internalType: 'address' }],
        outputs: [
            { name: 'outcome', type: 'uint256', internalType: 'uint256' },
        ],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'userWithdrawals',
        inputs: [{ name: 'user', type: 'address', internalType: 'address' }],
        outputs: [{ name: 'amount', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'withdrawWinnings',
        inputs: [],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'event',
        name: 'BetPlaced',
        inputs: [
            {
                name: 'bettor',
                type: 'address',
                indexed: true,
                internalType: 'address',
            },
            {
                name: 'outcome',
                type: 'uint256',
                indexed: false,
                internalType: 'uint256',
            },
            {
                name: 'amount',
                type: 'uint256',
                indexed: false,
                internalType: 'uint256',
            },
        ],
        anonymous: false,
    },
    {
        type: 'event',
        name: 'FundsWithdrawn',
        inputs: [
            {
                name: 'user',
                type: 'address',
                indexed: true,
                internalType: 'address',
            },
            {
                name: 'amount',
                type: 'uint256',
                indexed: false,
                internalType: 'uint256',
            },
        ],
        anonymous: false,
    },
    {
        type: 'event',
        name: 'MarketCancelled',
        inputs: [],
        anonymous: false,
    },
    {
        type: 'event',
        name: 'MarketResolved',
        inputs: [
            {
                name: 'outcome',
                type: 'uint256',
                indexed: false,
                internalType: 'uint256',
            },
        ],
        anonymous: false,
    },
    { type: 'event', name: 'MarketUpdated', inputs: [], anonymous: false },
    {
        type: 'event',
        name: 'OwnershipTransferred',
        inputs: [
            {
                name: 'previousOwner',
                type: 'address',
                indexed: true,
                internalType: 'address',
            },
            {
                name: 'newOwner',
                type: 'address',
                indexed: true,
                internalType: 'address',
            },
        ],
        anonymous: false,
    },
    {
        type: 'event',
        name: 'PlatformDetailsUpdated',
        inputs: [
            {
                name: 'platformAddress',
                type: 'address',
                indexed: false,
                internalType: 'address',
            },
            {
                name: 'platformFee',
                type: 'uint256',
                indexed: false,
                internalType: 'uint256',
            },
        ],
        anonymous: false,
    },
    {
        type: 'event',
        name: 'RewardsAmplified',
        inputs: [
            {
                name: 'sponsor',
                type: 'address',
                indexed: true,
                internalType: 'address',
            },
            {
                name: 'amount',
                type: 'uint256',
                indexed: false,
                internalType: 'uint256',
            },
        ],
        anonymous: false,
    },
    {
        type: 'event',
        name: 'WinningsWithdrawn',
        inputs: [
            {
                name: 'user',
                type: 'address',
                indexed: true,
                internalType: 'address',
            },
            {
                name: 'amount',
                type: 'uint256',
                indexed: false,
                internalType: 'uint256',
            },
        ],
        anonymous: false,
    },
    {
        type: 'error',
        name: 'AddressEmptyCode',
        inputs: [{ name: 'target', type: 'address', internalType: 'address' }],
    },
    {
        type: 'error',
        name: 'AddressInsufficientBalance',
        inputs: [{ name: 'account', type: 'address', internalType: 'address' }],
    },
    { type: 'error', name: 'FailedInnerCall', inputs: [] },
    {
        type: 'error',
        name: 'InvalidAllowance',
        inputs: [{ name: 'amount', type: 'uint256', internalType: 'uint256' }],
    },
    {
        type: 'error',
        name: 'InvalidAmount',
        inputs: [{ name: 'error', type: 'string', internalType: 'string' }],
    },
    {
        type: 'error',
        name: 'InvalidMarketState',
        inputs: [{ name: 'error', type: 'string', internalType: 'string' }],
    },
    {
        type: 'error',
        name: 'InvalidOutcome',
        inputs: [{ name: 'outcome', type: 'uint256', internalType: 'uint256' }],
    },
    {
        type: 'error',
        name: 'InvalidUserAction',
        inputs: [{ name: 'error', type: 'string', internalType: 'string' }],
    },
    {
        type: 'error',
        name: 'OwnableInvalidOwner',
        inputs: [{ name: 'owner', type: 'address', internalType: 'address' }],
    },
    {
        type: 'error',
        name: 'OwnableUnauthorizedAccount',
        inputs: [{ name: 'account', type: 'address', internalType: 'address' }],
    },
    {
        type: 'error',
        name: 'SafeERC20FailedOperation',
        inputs: [{ name: 'token', type: 'address', internalType: 'address' }],
    },
];
