import { ethers } from 'ethers';
import { PREDICTO_ABI } from '../abi/predicto.abi';

export const annouceWinner = async (address, selectedIndex) => {
    try {
        if (!window.ethereum) {
            return;
        }
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        const predictionContractInstance = new ethers.Contract(
            address,
            PREDICTO_ABI,
            signer
        );
        const predictionTrx = await predictionContractInstance.resolveMarket(
            selectedIndex
        );
        const receipt = await predictionTrx.wait();
        console.log('Prediction announced successfully');
        return receipt?.hash;
    } catch (err) {
        console.log(err);
    }
};
