import { create } from 'zustand';

const initialState = {
    address: '',
    predictionData: [],
};

const useGlobalStorage = create((set) => ({
    address: initialState.address,
    setAddress: (address) => set({ address }),
    predictionData: initialState.predictionData,
    setPredictionData: (predictionData) => set({ predictionData }),
}));

export default useGlobalStorage;
