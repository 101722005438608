import React, { useEffect, useState } from 'react';
import { FaWallet, FaPowerOff } from 'react-icons/fa6';
import { Link, useLocation } from 'react-router-dom';
import CONFIG from '../../config/config.wods';
import WODS_PIC from '../../assets/wods-pic.jpg';
import { connectWallet } from '../../extra/api/api.connectWallet';
import useGlobalStorage from '../../store';

export default function Header() {
    const [error, setError] = useState(null);
    const { address, setAddress } = useGlobalStorage();
    const location = useLocation();

    const navLinks = [
        { name: 'Predictions', path: '/predict' },
        { name: 'Create Predictions', path: '/create-prediction' },
        {
            name: 'Docs',
            path: 'https://wodsfun.hashnode.space/default-guide/init/wods',
        },
    ];

    // Format the wallet address for display
    const formatWalletAddress = (address) => {
        if (!address) return '';
        return `${address.slice(0, 6)}...${address.slice(-4)}`;
    };

    // Connect wallet and store address
    const handleConnectWallet = async () => {
        try {
            const response = await connectWallet();
            if (response && response.walletAddress) {
                setAddress(response.walletAddress);
                localStorage.setItem('walletAddress', response.walletAddress); // Save address to local storage
                setError(null);
            } else {
                throw new Error('Failed to connect wallet. Please try again.');
            }
        } catch (err) {
            console.error('Wallet connection error:', err);
            setError(err.message || 'Unknown error occurred');
        }
    };

    // Disconnect wallet and clear address
    const handleDisconnectWallet = () => {
        setAddress(null);
        localStorage.removeItem('walletAddress'); // Remove address from local storage
    };

    // Restore wallet address on component mount
    useEffect(() => {
        const savedAddress = localStorage.getItem('walletAddress');
        if (savedAddress) {
            setAddress(savedAddress);
        }
    }, []);

    return (
        <header className="box header-box mb-4">
            <div className="flex items-center justify-between w-full">
                {/* Logo and Title */}
                <div className="flex items-center gap-4">
                    <img src={WODS_PIC} className="w-10 h-10" alt="wods-pic" />
                    <div>
                        <div
                            className="glitch-text text-yellow-500 cursor-pointer text-lg"
                            onClick={() => (window.location.href = '/')}
                        >
                            WODS
                        </div>
                        <small>Predict the future</small>
                    </div>
                </div>

                {/* Navigation Links */}
                <nav className="flex gap-8 text-white">
                    {navLinks.map((link) => (
                        <Link
                            key={link.name}
                            to={link.path}
                            className="hover:text-yellow-500 transition duration-200"
                        >
                            {link.name}
                        </Link>
                    ))}
                </nav>

                {/* Wallet Connection / Buy WODS */}
                {location.pathname === '/create-prediction' ? (
                    <button
                        className="app-button-community ticker flex"
                        onClick={
                            address
                                ? handleDisconnectWallet
                                : handleConnectWallet
                        }
                    >
                        <div className="ticker flex items-center">
                            {address ? (
                                <>
                                    {formatWalletAddress(address)}
                                    <FaPowerOff className="ml-2 cursor-pointer" />
                                </>
                            ) : (
                                'Connect Wallet'
                            )}
                        </div>
                    </button>
                ) : (
                    <button
                        className="app-button-community ticker flex"
                        onClick={() =>
                            window.open(CONFIG.BUY_WODS_LINK, '_blank')
                        }
                    >
                        <div className="ticker flex items-center">
                            Buy&nbsp;
                            <FaWallet className="mr-2" />
                            WODS
                        </div>
                    </button>
                )}
            </div>

            {/* Error Message */}
            {error && <div className="text-red-500 text-sm mt-2">{error}</div>}
        </header>
    );
}
