// src/utils/walletConnect.js
import { CHAIN_CONFIG } from '../../config/config.wods';

export const BASE_NETWORK_PARAMS = CHAIN_CONFIG;

export const connectWallet = async () => {
    if (!window.ethereum) {
        throw new Error(
            'MetaMask is not installed. Please install MetaMask to connect your wallet.'
        );
    }

    try {
        // Forcefully request account connection, this should trigger the popup
        const accounts = await window.ethereum.request({
            method: 'eth_requestAccounts',
        });

        // Check the current chainId
        const currentChainId = await window.ethereum.request({
            method: 'eth_chainId',
        });

        // If we're not on the Base network, switch networks
        if (currentChainId !== BASE_NETWORK_PARAMS.chainId) {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: BASE_NETWORK_PARAMS.chainId }],
            });
        }

        // Return the connected wallet address
        return { walletAddress: accounts[0] };
    } catch (error) {
        if (error.code === 4902) {
            // Network is not available, try adding it
            try {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [BASE_NETWORK_PARAMS],
                });
            } catch (addError) {
                console.error('Error adding network:', addError);
                throw new Error(
                    'Failed to add Base network. Please try again later.'
                );
            }
        } else if (error.code === 4001) {
            // User rejected connection request
            throw new Error('User rejected the connection request.');
        } else {
            console.error('Error during wallet connection:', error);
            throw new Error('Failed to connect wallet. Please try again.');
        }
    }
};
