import React, { useEffect, useState } from 'react';
import { fetchPredictions } from '../../../extra/api/api.fetchPredictions';
import { MarketGrid } from '../Campaigns/MarketGrid';
import useGlobalStorage from '../../../store';

const AnnouceCampaign = () => {
    const [data, setData] = useState([]);
    const { address } = useGlobalStorage();

    const fetchData = async () => {
        try {
            const result = await fetchPredictions();
            setData(result || []); // Set data to an empty array if `result` is falsy
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []); // Dependency array ensures `fetchData` runs only on mount

    const userData = data.filter(
        (elem) => address && elem.admin.toLowerCase() === address.toLowerCase()
    );

    return (
        <div className="grid grid-cols-3 gap-3">
            {userData.map((elem) => (
                <div key={elem.id || elem.admin}>
                    <MarketGrid data={elem} type="announce" />
                </div>
            ))}
        </div>
    );
};

export default AnnouceCampaign;
