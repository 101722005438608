import { WOODS_TOKEN } from '../../config/config.wods';
import { PREDICTO_ABI } from '../abi/predicto.abi';
import { TOKEN_CONTRACT_ABI } from '../abi/token.abi';
import { ethers } from 'ethers';
export const handlePrediction = async ({ addr, amount, index }) => {
    try {
        if (!window.ethereum) {
            alert('Metamask not found');
            return;
        }
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();

        const approveContractInstace = new ethers.Contract(
            WOODS_TOKEN,
            TOKEN_CONTRACT_ABI,
            signer
        );
        const approveResponse = await approveContractInstace.approve(
            addr,
            ethers.parseUnits(amount.toString(), 18)
        );
        await approveResponse.wait();
        const participateContractInstance = new ethers.Contract(
            addr,
            PREDICTO_ABI,
            signer
        );
        const participateTrx = await participateContractInstance.participate(
            index,
            ethers.parseUnits(amount.toString(), 18)
        );
        const receipt = await participateTrx.wait();
        return receipt?.hash;
    } catch (err) {
        console.log(err);
    }
};
