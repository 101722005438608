import React, { useCallback, useEffect, useState } from 'react';
import { fetchPriceData } from '../../../extra/api/api.fetchBalance';
import { fetchPredictions } from '../../../extra/api/api.fetchPredictions';
import { Activities } from './Activities';
import { Categories } from './Categories';
import { Footer } from './Footer';
import { Header } from './Header';
import { MarketGrid } from './MarketGrid';
import { TokenInfo } from './TokenInfo';
import { TrendingMarkets } from './TrendingMarkets';
import useGlobalStorage from '../../../store';
import { Toaster } from 'react-hot-toast';

function App() {
    const [tokenData, setTokenData] = useState({
        price: null,
        priceChange: null,
        loading: true,
        error: null,
    });

    const { predictionData, setPredictionData, setAddress } =
        useGlobalStorage();
    const fetchData = useCallback(async () => {
        try {
            const result = await fetchPredictions();
            setPredictionData(result ? result : []);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, []);
    const fetchBalance = async () => {
        const TOKEN_DATA = await fetchPriceData();
        setTokenData({
            price: TOKEN_DATA?.price || 'N/A',
            priceChange: TOKEN_DATA?.priceChange24h || 0,
            loading: false,
            error: null,
            ...TOKEN_DATA,
        });
    };
    const fetchAddress = async () => {
        if (!window.ethereum) {
            throw new Error(
                'MetaMask is not installed. Please install MetaMask to connect your wallet.'
            );
        }
        const accounts = await window.ethereum.request({
            method: 'eth_requestAccounts',
        });
        setAddress(accounts[0]);
    };
    useEffect(() => {
        fetchData();
    }, [fetchData]);
    useEffect(() => {
        fetchBalance();
        fetchAddress();
    }, []);
    console.log(predictionData);
    return (
        <>
            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    // Define default options
                    className: '',
                    duration: 5000,
                    style: {
                        background: '#363636',
                        color: '#fff',
                    },

                    // Default options for specific types
                    success: {
                        duration: 3000,
                    },
                }}
            />
            <div className="min-h-screen bg-[#0D0D0D] text-white">
                <Header />

                <main className="container mx-auto px-4 py-6 space-y-8">
                    <TrendingMarkets tokenData={tokenData} />
                    <Categories />
                    <div className="grid grid-cols-3 gap-3">
                        {predictionData
                            ?.slice()
                            .reverse()
                            ?.map((elem, index) => {
                                return (
                                    <div key={index}>
                                        <MarketGrid data={elem} />
                                    </div>
                                );
                            })}
                    </div>

                    {/* <Activities /> */}
                    <TokenInfo tokenData={tokenData} />
                </main>

                <Footer />
            </div>
        </>
    );
}

export default App;
