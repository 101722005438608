import React from 'react';
import { Clock, Flame, TrendingUp } from 'lucide-react';
import useGlobalStorage from '../../../store';

export const TrendingMarkets = ({ tokenData }) => {
    const { predictionData } = useGlobalStorage();

    const renderTokenInfo = () => {
        if (tokenData?.loading) {
            return <span className="text-gray-400">Loading...</span>;
        }
        if (tokenData?.error) {
            return <span className="text-red-400">{tokenData.error}</span>;
        }

        return (
            <>
                <span className="text-yellow-500">$WODS:</span>
                <span className="ml-2">$ {tokenData.price}</span>
                <span
                    className={`ml-2 ${
                        tokenData.priceChange > 0
                            ? 'text-green-400'
                            : 'text-red-400'
                    }`}
                >
                    {tokenData.priceChange > 0 ? '+' : ''}
                    {tokenData.priceChange}%
                </span>
            </>
        );
    };

    const renderMarketCard = (market, index) => {
        let { title, description, category } = market;

        try {
            // If the title contains JSON, parse it.
            if (title.includes('{')) {
                const resolvedData = JSON.parse(title);
                title = resolvedData.name || title;
                description = resolvedData.description || description;
                category = resolvedData.category || 'crypto';
            }
        } catch (error) {
            console.error('Error parsing market title:', error);
        }

        return (
            <div
                key={market.id || index}
                className="bg-black border border-yellow-500/20 rounded-lg p-4 hover:border-yellow-500/40 transition-colors"
            >
                <div className="flex items-start justify-between mb-2">
                    <h3 className="font-semibold">{title}</h3>
                    <div className="flex items-center space-x-2">
                        {index === 0 && (
                            <span className="flex items-center text-orange-500">
                                <Flame className="w-4 h-4" />
                            </span>
                        )}
                        {market.endingSoon && (
                            <span className="text-xs px-2 py-1 bg-red-500/20 text-red-400 rounded">
                                Ending Soon
                            </span>
                        )}
                    </div>
                </div>
                <div className="flex items-center text-sm text-gray-400">
                    <Clock className="w-4 h-4 mr-1" />
                    Ends {new Date(market.date).toLocaleDateString()}
                </div>
            </div>
        );
    };

    return (
        <section className="py-6">
            {/* Header */}
            <div className="flex items-center justify-between mb-4">
                <div className="flex items-center">
                    <TrendingUp className="w-5 h-5 text-yellow-500 mr-2" />
                    <h2 className="text-xl font-bold">Trending Markets</h2>
                </div>
                <div className="hidden md:flex items-center px-3 py-1 bg-yellow-500/10 rounded-md">
                    {renderTokenInfo()}
                </div>
            </div>

            {/* Markets Grid */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {predictionData
                    ?.slice(0, 3)
                    .map((market, index) => renderMarketCard(market, index))}
            </div>
        </section>
    );
};
