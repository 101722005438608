import { PinataSDK } from 'pinata-web3';
import { PINATA_JWT, PINATA_GATEWAY } from '../../config/config.wods';

// Initialize the Pinata SDK with configuration
const pinata = new PinataSDK({
    pinataJwt: PINATA_JWT,
    pinataGateway: PINATA_GATEWAY,
});

/**
 * Uploads a file to Pinata Cloud.
 * @param {File} file - The file to be uploaded.
 * @returns {Promise<Object>} A promise that resolves to an object containing the IPFS hash or an error message.
 */
export const uploadImageToPinataCloud = async (file) => {
    try {
        // Validate the file input
        if (!(file instanceof File)) {
            throw new Error(
                'Invalid file. Please provide a valid File object.'
            );
        }

        // Upload the file to Pinata
        const upload = await pinata.upload.file(file);

        // Return a successful response
        return {
            IPFS_HASH: upload.IpfsHash,
            SUCCESS: true,
        };
    } catch (error) {
        console.error('Error uploading file to Pinata:', error);

        // Return a failure response
        return {
            error: error.message || 'An unknown error occurred',
            SUCCESS: false,
        };
    }
};

/**
 * Fetches an image from IPFS using its hash.
 * @param {string} ipfsHash - The IPFS hash of the file to fetch.
 * @returns {Promise<void>} Logs the fetched data or error.
 */
export const fetchImageFromPinataCloud = async (ipfsHash) => {
    try {
        if (!ipfsHash || typeof ipfsHash !== 'string') {
            throw new Error('Invalid IPFS hash. Please provide a valid hash.');
        }

        // Fetch the file from IPFS using the Pinata Gateway
        const data = await pinata.gateways.get(ipfsHash);

        // Log the fetched data
        return data;
    } catch (error) {
        console.error('Error fetching image from IPFS:', error);
    }
};

// Example usage (Uncomment to test functionality)
/*
(async () => {
    // Upload a test file
    const testFile = new File(['Hello, world!'], 'example.txt', { type: 'text/plain' });
    const uploadResponse = await uploadImageToPinataCloud(testFile);
    console.log('Upload Response:', uploadResponse);

    // Fetch a file using a sample IPFS hash (replace with a real hash)
    const sampleIpfsHash = 'bafkreibm6jg3ux5qumhcn2b3flc3tyu6dmlb4xa7u5bf44yegnrjhc4yeq';
    await fetchImageFromIPFS(sampleIpfsHash);
})();
*/
