import { FACTORY_CONTRACT_ADDRESS } from '../../config/config.wods';
import { FETCH_TRANSACTION_RPC } from '../../config/config.wods';
import { ADMIN_FACTORY_ABI } from '../abi/admin.abi';
import { ethers } from 'ethers';
import { PREDICTO_ABI } from '../abi/predicto.abi';
export const fetchPredictions = async () => {
    const provider = new ethers.JsonRpcProvider(FETCH_TRANSACTION_RPC);
    const contractInstance = new ethers.Contract(
        FACTORY_CONTRACT_ADDRESS,
        ADMIN_FACTORY_ABI,
        provider
    );

    const marketAddresses = await contractInstance.getAllMarkets();
    console.log('Total Market Addresses:', marketAddresses.length); // Log total market addresses

    const batchSize = 5; // Maximum batch size
    let marketData = [];
    let totalFetched = 0;

    for (let i = 0; i < marketAddresses.length; i += batchSize) {
        const batch = marketAddresses.slice(i, i + batchSize);
        console.log(`Fetching batch from ${i} to ${i + batchSize - 1}...`); // Log which batch is being fetched

        const batchData = await Promise.all(
            batch.map(async (addr) => {
                try {
                    console.log(`Processing address ${addr}`);
                    const predictionInstance = new ethers.Contract(
                        addr,
                        PREDICTO_ABI,
                        provider
                    );

                    const response = await predictionInstance.market();
                    const outcomes1 = await predictionInstance.outcomes(0);
                    const outcomes2 = await predictionInstance.outcomes(1);
                    const finalOutcome = response[13]
                        ? parseInt(response[12])
                        : null;
                    const finalVal =
                        finalOutcome !== null
                            ? await predictionInstance.outcomes(finalOutcome)
                            : null;
                    const participate =
                        finalOutcome !== null
                            ? await predictionInstance.getParticipants(
                                  finalOutcome
                              )
                            : null;

                    return {
                        admin: response[0],
                        title: response[2],
                        options1: outcomes1,
                        options2: outcomes2,
                        date: new Date(parseInt(response[10]) * 1000),
                        total_bets: parseInt(response[8]),
                        total_rewards: parseInt(response[7]) / 1e18,
                        address: addr,
                        finalOutcome: finalVal ? finalVal[0] : null,
                        participate: participate ? participate : [],
                        outcomePercentage1:
                            (parseInt(outcomes1[2]) / parseInt(response[8])) *
                            100,
                        outcomePercentage2:
                            (parseInt(outcomes2[2]) / parseInt(response[8])) *
                            100,
                    };
                } catch (err) {
                    console.error(`Error processing address ${addr}:`, err);
                    return null; // Skip invalid or failed address
                }
            })
        );

        // Append successfully fetched data
        const validData = batchData.filter(Boolean); // Filter out nulls
        marketData = [...marketData, ...validData];
        totalFetched += validData.length;

        console.log(
            `Fetched batch of ${validData.length} items. Total fetched so far: ${totalFetched}`
        );
    }

    return marketData;
};
