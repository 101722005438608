import React from 'react';
import { Twitter, MessageCircle, Github } from 'lucide-react';
import { Link } from 'react-router-dom';

export function Footer() {
    return (
        <footer className="bg-black border-t border-yellow-500/20 py-8">
            <div className="container mx-auto px-4">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
                    <div>
                        <h3 className="text-yellow-500 font-bold text-lg mb-4">THE DUMB STREET</h3>
                        <p className="text-gray-400 text-sm">
                            The most degen prediction market in crypto.
                            Built by degens, for degens.
                        </p>
                    </div>

                    <div>
                        <h4 className="font-semibold mb-4">Quick Links</h4>
                        <ul className="space-y-2 text-sm text-gray-400">
                            <li><Link to="/markets" className="hover:text-yellow-500">Markets</Link></li>
                            <li><Link to="/leaderboard" className="hover:text-yellow-500">Leaderboard</Link></li>
                            <li><Link to="/token" className="hover:text-yellow-500">Token</Link></li>
                            <li><Link to="/documentation" className="hover:text-yellow-500">Documentation</Link></li>
                        </ul>
                    </div>

                    <div>
                        <h4 className="font-semibold mb-4">Resources</h4>
                        <ul className="space-y-2 text-sm text-gray-400">
                            <li><Link to="/faq" className="hover:text-yellow-500">FAQ</Link></li>
                            <li><Link to="/terms" className="hover:text-yellow-500">Terms of Service</Link></li>
                            <li><Link to="/privacy" className="hover:text-yellow-500">Privacy Policy</Link></li>
                            <li><Link to="/contact" className="hover:text-yellow-500">Contact</Link></li>
                        </ul>
                    </div>

                    <div>
                        <h4 className="font-semibold mb-4">Community</h4>
                        <div className="flex space-x-4">
                            <a href="#" className="text-gray-400 hover:text-yellow-500">
                                <Twitter className="w-5 h-5" />
                            </a>
                            <a href="#" className="text-gray-400 hover:text-yellow-500">
                                <MessageCircle className="w-5 h-5" />
                            </a>
                            <a href="#" className="text-gray-400 hover:text-yellow-500">
                                <Github className="w-5 h-5" />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="mt-8 pt-8 border-t border-yellow-500/20 text-center text-sm text-gray-400">
                    © {new Date().getFullYear()} The Dumb Street. All rights reserved.
                </div>
            </div>
        </footer>
    );
}