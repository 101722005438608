import React from 'react';
import { Wallet, Monitor, Music, Vote, Trophy, Zap } from 'lucide-react';

const categories = [
    { id: 'crypto', name: 'Crypto', icon: Wallet },
    { id: 'technology', name: 'Technology', icon: Monitor },
    { id: 'culture', name: 'Culture', icon: Music },
    { id: 'politics', name: 'Politics', icon: Vote },
    { id: 'sports', name: 'Sports', icon: Trophy },
    { id: 'breaking', name: 'Breaking', icon: Zap },
];

export function Categories() {
    return (
        <section className="py-6">
            <div className="grid grid-cols-2 md:grid-cols-6 gap-4">
                {categories.map(({ id, name, icon: Icon }) => (
                    <button
                        key={id}
                        className="flex flex-col items-center justify-center p-4 bg-black border border-yellow-500/20 rounded-lg hover:border-yellow-500/40 transition-colors"
                    >
                        <Icon className="w-6 h-6 text-yellow-500 mb-2" />
                        <span className="text-sm font-medium">{name}</span>
                    </button>
                ))}
            </div>
        </section>
    );
}