import { ethers } from 'ethers';
import { CHAIN_CONFIG, WOODS_TOKEN } from '../../config/config.wods';
import { FACTORY_CONTRACT_ADDRESS } from '../../config/config.wods';
import { ADMIN_FACTORY_ABI } from '../abi/admin.abi';
import { uploadImageToPinataCloud } from './api.uploadImage';

/**
 * Checks if MetaMask is installed.
 * @throws {Error} If MetaMask is not installed.
 */
const checkMetamask = () => {
    if (!window.ethereum) {
        throw new Error('MetaMask not found. Please install MetaMask.');
    }
    return true;
};

/**
 * Connects to the blockchain using MetaMask.
 * @returns {Promise<Object>} Provider and signer.
 */
const connectToBlockchain = async () => {
    try {
        const provider = new ethers.BrowserProvider(window.ethereum);

        // Request account access
        await window.ethereum.request({ method: 'eth_requestAccounts' });

        // Ensure the correct chain is added and selected
        await provider.send('wallet_addEthereumChain', [CHAIN_CONFIG]);
        await provider.send('wallet_switchEthereumChain', [
            { chainId: CHAIN_CONFIG.chainId },
        ]);

        const signer = await provider.getSigner();
        return { provider, signer };
    } catch (error) {
        console.error('Blockchain connection error:', error);
        throw new Error(
            'Failed to connect to blockchain. Please check your MetaMask.'
        );
    }
};

/**
 * Deploys a market using the contract instance.
 * @param {Object} contractInstance - Contract instance.
 * @param {string} desc - Description JSON string.
 * @param {Array} outcomes - Possible outcomes.
 * @param {number} token_decimal - Token decimal precision.
 * @param {number} endTimestamp - Market end timestamp.
 * @param {number} minPrice - Minimum price.
 * @param {number} maxPrice - Maximum price.
 * @returns {Promise<Object>} Market deployment result.
 */
const deployMarket = async (
    contractInstance,
    desc,
    outcomes,
    token_decimal,
    endTimestamp,
    minPrice,
    maxPrice
) => {
    try {
        const transactionResponse = await contractInstance.deployMarket(
            WOODS_TOKEN,
            desc,
            outcomes,
            token_decimal,
            endTimestamp,
            ethers.parseUnits(minPrice.toString(), 18),
            ethers.parseUnits(maxPrice.toString(), 18)
        );

        const receipt = await transactionResponse.wait();
        const platformDetails = await contractInstance.getPlatformDetails();
        return {
            hash: receipt?.hash,
            address: receipt?.logs[0]?.address,
            platformAddress: platformDetails[0],
            platformFee: Number(platformDetails[1]),
        };
    } catch (error) {
        console.error('Market deployment error:', error);
        throw error;
    }
};

/**
 * Writes data to the blockchain contract.
 * @param {File} imageFile - Image file to upload.
 * @param {Object} DescriptionJSON - Description object.
 * @param {Array} outcomes - Possible outcomes.
 * @param {number} token_decimal - Token decimal precision.
 * @param {number} endTimestamp - Market end timestamp.
 * @param {number} minPrice - Minimum price.
 * @param {number} maxPrice - Maximum price.
 * @returns {Promise<Object>} Contract writing result.
 */
const writeToContract = async (
    imageFile,
    DescriptionJSON,
    outcomes,
    token_decimal,
    endTimestamp,
    minPrice,
    maxPrice
) => {
    try {
        checkMetamask();

        const { signer } = await connectToBlockchain();

        // Upload image to Pinata and get the IPFS hash
        const IMAGE_RES = await uploadImageToPinataCloud(imageFile);
        const desc = JSON.stringify({
            ...DescriptionJSON,
            'image-hash': IMAGE_RES.IPFS_HASH,
        });

        // Get the contract instance
        const contractInstance = new ethers.Contract(
            FACTORY_CONTRACT_ADDRESS,
            ADMIN_FACTORY_ABI,
            signer
        );

        // Deploy the market
        const RES = await deployMarket(
            contractInstance,
            desc,
            outcomes,
            token_decimal,
            endTimestamp,
            minPrice,
            maxPrice
        );

        return RES;
    } catch (error) {
        console.error('Error writing to contract:', error);
        throw error;
    }
};

export default writeToContract;
