import React from 'react';
import CampaignFormUI from './CampaignFormUI';
import Header from '../../Common/Header';
import Footer from '../../Common/Footer';

const CampaignForm = () => {
    return (
        <div className="min-h-screen bg-black">
            <Header />
            <div className="mb-4 box">
                <CampaignFormUI />
            </div>
            <Footer />
        </div>
    );
};

export default CampaignForm;
