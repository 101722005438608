import { AlertCircle, X } from 'lucide-react';
import React, { useId } from 'react';
import { annouceWinner } from '../../../extra/api/api.announceWinner';
import toast from 'react-hot-toast';
import { getGravatarUrl } from './MarketGrid';
import { PINATA_GATEWAY } from '../../../config/config.wods';

const AnnounceModal = ({ data, onClose }) => {
    const [selected, setSelected] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [link, setLink] = React.useState('');

    const handleAnnouce = async () => {
        try {
            setLoading(true);
            const res = await annouceWinner(data.address, selectedIndex);
            setLoading(false);
            if (res) {
                setLink(res);
                toast.success('Annoucement successful');
            } else {
                setLoading(false);
                toast.error('Something went wrong');
            }
        } catch (err) {
            setLoading(false);
            toast.error('Something went wrong');
        }
    };

    const id = useId();
    let title = data.title;
    let description = data.title;
    let category = data?.category ? data.category : 'crypto';
    let image = getGravatarUrl(category, id);

    if (data.title.includes('{')) {
        const resolvedData = JSON.parse(data.title);
        title = resolvedData.name;
        description = resolvedData.description;
        if (resolvedData['image-hash']) {
            image = `https://${PINATA_GATEWAY}/ipfs/${resolvedData['image-hash']}`;
        }
    }

    return (
        <div className="fixed inset-0 bg-black/80 flex items-center justify-center p-4 z-50">
            <div className="bg-[#0D0D0D] border border-yellow-500/20 rounded-lg w-full max-w-lg">
                <div className="flex items-center justify-between p-4 border-b border-yellow-500/20">
                    <h3 className="text-lg font-bold">Annouce Result</h3>
                    <button
                        onClick={onClose}
                        className="text-gray-400 hover:text-white"
                    >
                        <X className="w-6 h-6" />
                    </button>
                </div>
                <div className="p-6 space-y-6">
                    <img
                        src={image}
                        alt={'image'}
                        className="w-full h-48 object-cover rounded-lg"
                    />

                    <div>
                        <h4 className="font-semibold">{title}</h4>
                        <p className="mb-2">{description}</p>
                        <div className="flex items-center text-sm text-gray-400">
                            <AlertCircle className="w-4 h-4 mr-2" />
                            Voting ends in{' '}
                            {new Date(data.date).toCustomDateTimeString()}
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-2">
                        <button
                            className="px-4 py-2 bg-green-500/20 text-green-400 rounded-md hover:bg-green-500/30 transition-colors"
                            onClick={() => setSelectedIndex(0)}
                        >
                            {data.options1.map((option, index) => {
                                return (
                                    <div
                                        key={index}
                                        onClick={() => setSelected(option)}
                                    >
                                        {option}
                                    </div>
                                );
                            })}
                        </button>
                        <button
                            onClick={() => setSelectedIndex(1)}
                            className="px-4 py-2 bg-red-500/20 text-red-400 rounded-md hover:bg-red-500/30 transition-colors"
                        >
                            {data.options2.map((option, index) => {
                                return (
                                    <div
                                        key={index}
                                        onClick={() => setSelected(option)}
                                    >
                                        {option}
                                    </div>
                                );
                            })}
                        </button>
                    </div>
                </div>
                <div className="p-4 border-t border-yellow-500/20">
                    <button
                        onClick={handleAnnouce}
                        disabled={!selected || loading || link}
                        className="disabled:cursor-not-allowed w-full px-6 py-3 bg-yellow-500 text-black font-semibold rounded-lg hover:bg-yellow-400 transition-colors"
                    >
                        {loading ? (
                            <div className="flex items-center gap-x-3 justify-center">
                                Please wait
                                <div role="status">
                                    <svg
                                        aria-hidden="true"
                                        className="size-5 text-gray-200 animate-spin fill-black"
                                        viewBox="0 0 100 101"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="currentColor"
                                        />
                                        <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="currentFill"
                                        />
                                    </svg>
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        ) : selected ? (
                            `Annouce ${selected} as Winner`
                        ) : link ? (
                            <a
                                href={`https://basescan.org/tx/${link}`}
                                target="_blank"
                                rel="noreferrer"
                                className="flex items-center gap-x-2 justify-center"
                            >
                                View on explorer
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="size-5"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244"
                                    />
                                </svg>
                            </a>
                        ) : (
                            'Choose option'
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AnnounceModal;
