import React, { useState, useEffect } from 'react';
import { Wallet, MessageCircle, Menu, User, X } from 'lucide-react';
import { ProfileModal } from './ProfileModal';
import { connectWallet } from '../../../extra/api/api.connectWallet';
import { Link } from 'react-router-dom';
import useGlobalStorage from '../../../store';

export function Header() {
    const [isProfileOpen, setProfileOpen] = useState(false);
    const [error, setError] = useState(null);
    const { address, setAddress } = useGlobalStorage();
    const handleConnectWallet = async () => {
        try {
            const { walletAddress } = await connectWallet();
            setAddress(walletAddress);
            setError(null);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleDisconnectWallet = () => {
        setAddress(null);
    };

    const formatWalletAddress = (address) => {
        if (!address) return '';
        return `${address.slice(0, 6)}...${address.slice(-4)}`;
    };

    return (
        <>
            <header className="bg-black border-b border-yellow-500/20">
                <div className="container mx-auto px-4">
                    <div className="flex items-center justify-between h-16">
                        {/* Logo and Token Info */}
                        <div className="flex items-center space-x-4">
                            <div className="text-yellow-500 font-bold text-xl">
                                THE DUMB STREET
                            </div>
                        </div>
                        <div className="flex items-center gap-x-12">
                            <Link
                                to="/predict"
                                className={`cursor-pointer ${
                                    window.location.pathname === '/predict' &&
                                    'text-yellow-400'
                                }`}
                            >
                                Predictions
                            </Link>
                            <Link
                                to={'/create-prediction'}
                                className={`cursor-pointer ${
                                    window.location.pathname ===
                                        '/create-prediction' &&
                                    'text-yellow-400'
                                }`}
                            >
                                Create Predictions
                            </Link>
                        </div>

                        {/* Action Buttons */}
                        <div className="flex items-center space-x-4">
                            <button
                                onClick={() => setProfileOpen(true)}
                                className="text-yellow-500 hover:text-yellow-400"
                            >
                                <User className="w-5 h-5" />
                            </button>
                            {/* <a
                                href="#"
                                className="text-yellow-500 hover:text-yellow-400"
                            >
                                <MessageCircle className="w-5 h-5" />
                            </a> */}
                            {address ? (
                                <div className="flex items-center space-x-2 bg-yellow-500 px-3 py-2 rounded-md">
                                    <span className="text-black font-semibold">
                                        {formatWalletAddress(address)}
                                    </span>
                                    <button
                                        onClick={handleDisconnectWallet}
                                        className="text-black hover:text-red-600"
                                    >
                                        <X className="w-4 h-4" />
                                    </button>
                                </div>
                            ) : (
                                <button
                                    onClick={handleConnectWallet}
                                    className="hidden md:flex items-center px-4 py-2 bg-yellow-500 text-black font-semibold rounded-md hover:bg-yellow-400 transition-colors"
                                >
                                    <Wallet className="w-4 h-4 mr-2" />
                                    Connect Wallet
                                </button>
                            )}
                            <button className="md:hidden">
                                <Menu className="w-6 h-6 text-yellow-500" />
                            </button>
                        </div>
                    </div>
                </div>
            </header>

            {error && (
                <div className="text-red-500 text-center mt-2">{error}</div>
            )}

            <ProfileModal
                isOpen={isProfileOpen}
                onClose={() => setProfileOpen(false)}
            />
        </>
    );
}
