import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeContextProvider } from './ThemeContext';
import Home from '../components/Home/Home';
import CreateCampaign from '../components/Prediction/CreateCampaign/CampaignForm';
import Campaigns from '../components/Prediction/Campaigns';

export default function Root() {
    return (
        <ThemeContextProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/predict" element={<Campaigns />} />

                    <Route
                        path="/create-prediction"
                        element={<CreateCampaign />}
                    />
                </Routes>
            </BrowserRouter>
        </ThemeContextProvider>
    );
}
