import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CONFIG from '../../config/config.wods';
import { FaDollarSign } from 'react-icons/fa6';

const SectionBox = ({ title, content, children, urgentAlert }) => (
    <div className="box">
        <h3>{title}</h3>
        <p>{content}</p>
        {children}
        {urgentAlert && <p className="urgent-alert">{urgentAlert}</p>}
    </div>
);

const WolfOfDumbStreet = () => {
    const [showToast, setShowToast] = useState(false);
    useEffect(() => {
        const glitchTexts = document.querySelectorAll('.glitch-text');
        const glitchText = (element) => {
            const original = element.textContent;
            const glitched = original
                .split('')
                .map((char) =>
                    Math.random() > 0.8
                        ? String.fromCharCode(char.charCodeAt(0) + 1)
                        : char
                )
                .join('');

            element.textContent = glitched;
            setTimeout(() => (element.textContent = original), 100);
        };

        const intervalId = setInterval(() => {
            const randomText =
                glitchTexts[Math.floor(Math.random() * glitchTexts.length)];
            glitchText(randomText);
        }, 2000);

        return () => clearInterval(intervalId);
    }, []);

    const truncateAddress = (address) =>
        `${address.slice(0, 6)}...${address.slice(-4)}`;

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setShowToast(true);
        setTimeout(() => setShowToast(false), 3000); // Hide toast after 3 seconds
    };
    const socialLinks = Object.keys(CONFIG.SOCIAL_LINKS).map(
        (social_name, index) => (
            <Link
                key={index}
                to={''}
                onClick={() =>
                    window.open(CONFIG.SOCIAL_LINKS[social_name], '_blank')
                }
                className="social-link"
            >
                {social_name}
            </Link>
        )
    );

    const sections = [
        {
            title: 'PREDICTION STATUS',
            content: 'STATUS/W0DS: Too much hopium detected',
            urgentAlert: '⚠️ CRITICAL COPIUM LEVELS',
            children: (
                <>
                    <p>Loading next bad decision...</p>
                    <div className="loading-bar"></div>
                </>
            ),
        },
        {
            title: 'DUMB STREET CASINO',
            content: 'Last Prediction: TOTALLY ACCURATE',
            children: (
                <>
                    <p>Next Prediction: TRUST ME BRO</p>
                    <button
                        className="app-button-community ticker"
                        onClick={() => window.open(CONFIG.SOCIAL_LINKS.TG, '')}
                    >
                        Join the community
                    </button>
                </>
            ),
        },
        {
            title: 'Market Analysis',
            content: 'Trust level: In $WODS we trust',
            children: (
                <>
                    <div className="loading-bar"></div>
                    <p className="ticker" style={{ marginTop: '10px' }}>
                        Will $BTC Hit $100,000 ?
                    </p>
                </>
            ),
        },
        {
            title: 'DEGEN STATUS',
            content: (
                <ul className="mt-2">
                    <li>Wallet: $100,000</li>
                    <li>Brain: SMOOTH</li>
                    <li>Trading: FOMO ONLY</li>
                </ul>
            ),
            children: (
                <p className="warning-text mt-2">
                    EXTREME DEGEN ACTIVITY DETECTED
                </p>
            ),
        },
        {
            title: '$WODS METRICS',
            content: 'Price: MOON SOON™',
            urgentAlert: '🚨 BULLISH DIVERGENCE DETECTED',
            children: (
                <>
                    <p>Supply: TRUST THE MATH</p>
                    <div className="loading-bar"></div>
                </>
            ),
        },
        {
            title: 'Prediction Feed',
            content: 'Loading alpha leaks...',
            children: (
                <>
                    <p>Source: Voices in head</p>
                    <div className="loading-bar"></div>
                </>
            ),
        },
    ];

    return (
        <div className="layout-grid">
            {/* Header */}
            <header className="box header-box">
                <div>
                    <div className="glitch-text">DUMB MONEY MARKET</div>
                    <small>Predict the future</small>
                </div>
                <div className="contract-address cursor-pointer">
                    <div
                        className="text-yellow-400 font-mono text-lg"
                        onClick={() => copyToClipboard(CONFIG.CA_ADDRESS)}
                    >
                        CA: {CONFIG.CA_ADDRESS}
                    </div>
                </div>
                {/* Buy WODS Button */}
                <button
                    className="app-button-community ticker flex"
                    onClick={() => window.open(CONFIG.BUY_WODS_LINK, '_blank')}
                >
                    <div className="ticker flex items-center">
                        Buy&nbsp;
                        <FaDollarSign /> WODS
                    </div>
                </button>
            </header>
            {showToast && (
                <div className="fixed bottom-4 right-4 bg-green-600 text-white px-4 py-2 rounded shadow-lg transition-opacity duration-300 ease-in-out">
                    Contract address copied to clipboard! 🎉
                </div>
            )}
            {/* Sections */}
            {sections.map((section, index) => (
                <SectionBox
                    key={index}
                    title={<span className="glitch-text">{section.title}</span>}
                    content={
                        <span className="glitch-text">{section.content}</span>
                    }
                    urgentAlert={section.urgentAlert}
                >
                    {section.children}
                </SectionBox>
            ))}

            {/* Center Section */}
            <div className="center-section">
                <div className="logo-text">
                    <div>WOLF</div>
                    <div className="of">of</div>
                    <div>DUMB</div>
                    <div>STREET</div>
                </div>
                <button
                    className="app-button"
                    onClick={() => (window.location.href = '/predict')}
                >
                    Enter the App
                </button>
                <div className="coming-soon-text">Coming Soon™</div>
                <div className="social-links mt-4">{socialLinks}</div>
            </div>

            {/* Footer */}
            <footer className="box footer-box">
                <div className="social-links">
                    copyright - Wolf of Dumb Street
                </div>
                <div>54 • 19 998.5 • $WODS TO THE MOON</div>
            </footer>
        </div>
    );
};

export default WolfOfDumbStreet;
