import React from "react";
import { X, Wallet, History, Award, ExternalLink } from "lucide-react";

const recentVotes = [
    { id: 1, market: "BTC to 100k", vote: "YES", amount: "50 WODS", date: "2024-02-28" },
    { id: 2, market: "ETH 2.0 Launch", vote: "NO", amount: "30 WODS", date: "2024-02-27" },
    { id: 3, market: "SOL to $500", vote: "YES", amount: "25 WODS", date: "2024-02-26" },
];

export const ProfileModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black/80 flex items-center justify-center p-4 z-50">
            <div className="bg-[#0D0D0D] border border-yellow-500/20 rounded-lg w-full max-w-2xl">
                <div className="flex items-center justify-between p-4 border-b border-yellow-500/20">
                    <h3 className="text-lg font-bold">Profile</h3>
                    <button onClick={onClose} className="text-gray-400 hover:text-white">
                        <X className="w-6 h-6" />
                    </button>
                </div>

                <div className="p-6 space-y-6">
                    {/* Wallet Info */}
                    <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-3">
                            <div className="w-12 h-12 bg-yellow-500/20 rounded-full flex items-center justify-center">
                                <Wallet className="w-6 h-6 text-yellow-500" />
                            </div>
                            <div>
                                <div className="font-mono">0x1234...5678</div>
                                <div className="text-sm text-gray-400">Connected 2 hours ago</div>
                            </div>
                        </div>
                        <button className="px-4 py-2 bg-red-500/20 text-red-400 rounded-md hover:bg-red-500/30 transition-colors">
                            Disconnect
                        </button>
                    </div>

                    {/* Stats */}
                    <div className="grid grid-cols-3 gap-4">
                        <div className="bg-black border border-yellow-500/20 rounded-lg p-4">
                            <div className="text-gray-400 text-sm mb-1">Total Voted</div>
                            <div className="text-xl font-bold">105 WODS</div>
                        </div>
                        <div className="bg-black border border-yellow-500/20 rounded-lg p-4">
                            <div className="text-gray-400 text-sm mb-1">Win Rate</div>
                            <div className="text-xl font-bold text-green-400">68%</div>
                        </div>
                        <div className="bg-black border border-yellow-500/20 rounded-lg p-4">
                            <div className="text-gray-400 text-sm mb-1">Markets</div>
                            <div className="text-xl font-bold">12</div>
                        </div>
                    </div>

                    {/* Recent Activity */}
                    <div>
                        <h4 className="text-lg font-semibold mb-4 flex items-center">
                            <History className="w-5 h-5 mr-2" />
                            Recent Activity
                        </h4>
                        <div className="space-y-3">
                            {recentVotes.map((vote) => (
                                <div
                                    key={vote.id}
                                    className="flex items-center justify-between bg-black border border-yellow-500/20 rounded-lg p-3"
                                >
                                    <div>
                                        <div className="font-medium">{vote.market}</div>
                                        <div className="text-sm text-gray-400">Voted {vote.date}</div>
                                    </div>
                                    <div className="flex items-center space-x-4">
                                        <span
                                            className={`text-sm ${vote.vote === "YES" ? "text-green-400" : "text-red-400"
                                                }`}
                                        >
                                            {vote.vote}
                                        </span>
                                        <span className="text-yellow-500">{vote.amount}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Achievements */}
                    <div>
                        <h4 className="text-lg font-semibold mb-4 flex items-center">
                            <Award className="w-5 h-5 mr-2" />
                            Achievements
                        </h4>
                        <div className="grid grid-cols-2 gap-4">
                            <div className="bg-black border border-yellow-500/20 rounded-lg p-4 flex items-center space-x-3">
                                <div className="w-10 h-10 bg-yellow-500/20 rounded-full flex items-center justify-center">
                                    <Award className="w-5 h-5 text-yellow-500" />
                                </div>
                                <div>
                                    <div className="font-medium">Early Degen</div>
                                    <div className="text-sm text-gray-400">Joined in first week</div>
                                </div>
                            </div>
                            <div className="bg-black border border-yellow-500/20 rounded-lg p-4 flex items-center space-x-3">
                                <div className="w-10 h-10 bg-yellow-500/20 rounded-full flex items-center justify-center">
                                    <ExternalLink className="w-5 h-5 text-yellow-500" />
                                </div>
                                <div>
                                    <div className="font-medium">Market Maker</div>
                                    <div className="text-sm text-gray-400">Created 5+ markets</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};